'use client'
import { useRouter } from 'next/navigation'
import React from 'react'

export default function LandingMenuLink({
  href,
  children,
  target,
  onClick,
}: {
  target?: string
  href: string
  children: React.ReactNode
  onClick: () => void
}) {
  const router = useRouter()

  function handleLink(event: any, href: string) {
    event.preventDefault()
    onClick()
    router.push(href)
  }

  return (
    <a
      onClick={() => handleLink(event, href)}
      className={'text-black hover:underline'}
      target={target}
      href={href}
    >
      {children}
    </a>
  )
}
