'use client'
import LandingMenuLink from '@jobmojito/admin-app/(landing)/landing/components/LandingMenuLink'
import { Button } from '@nextui-org/button'
import { NavbarContent, Navbar as NextUiNavbar } from '@nextui-org/navbar'
import NextLink from 'next/link'
import React from 'react'
import { useTranslations } from 'next-intl'
import LanguageSelector from '@jobmojito/components/core/LanguageSelector'
import {
  findLanguage,
  language,
  languagesSorted,
} from '@jobmojito/admin-app/utils/data/platform-languages'

export default function Navbar({
  merchantLogo,
  isFooter = false,
}: {
  merchantLogo: React.JSX.Element
  isFooter?: boolean
}) {
  const t = useTranslations('component-admin-landing')
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <NextUiNavbar
      maxWidth={'xl'}
      isBordered={!isFooter}
      className={'text-base bg-white fixed'}
      onMenuOpenChange={setIsMenuOpen}
      isMenuOpen={isMenuOpen}
    >
      <div className={'w-40 md:w-44 h-full'}>
        <div className="relative w-full h-full flex flex-row items-center">
          {merchantLogo}
        </div>
      </div>
      <NavbarContent justify={'end'}>
        <div
          className={'hidden lg:flex flex-row gap-6 grow max-w-[620px] lg:mr-8'}
        >
          <LandingMenuLink
            href={'https://jobmojito.com/'}
            onClick={closeMenu}
            target="_blank"
          >
            {t('Home')}
          </LandingMenuLink>
          <LandingMenuLink
            href={'https://jobmojito.com/support'}
            onClick={closeMenu}
            target="_blank"
          >
            {t('Support')}
          </LandingMenuLink>
        </div>

        <LanguageSelector
            languages={languagesSorted.filter((l) =>
              l.enable_admin_interface===true
            )}
          />

        <Button
          aria-label="Login or Register"
          href={'/login'}
          as={NextLink}
          className={'border-1'}
          color={'primary'}
          size={'md'}
          radius={'sm'}
        >
          {t('Login & Sign up')}
        </Button>
      </NavbarContent>
    </NextUiNavbar>
  )
}
